import React from 'react'
import int2 from '../img/int2.svg'
import korinver from '../img/korinver.svg'
import willdom from '../img/willdom.svg'
import { add } from 'date-fns';
import { useTicker } from '../hooks/Ticker';

const futureDate = add(new Date(), {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 45
});

const Landing = () => {

  const { seconds, isTimeUp } = useTicker(futureDate);
  const redirect = isTimeUp ? (
      window.location.href = 'https://willdom.com'
  ) : null;

  return (
      <div className='flex flex-col w-screen text-left md:text-xl lg:text-3xl lg:pt-16 lg:px-12 lg:leading-relaxed'>
        <header className='flex flex-col'>
          <div><h2 className='text-3xl md:text-4xl lg:text-5xl text-center md:text-left p-1 md:p-4 font-bold'>Korinver and Willdom started a Strategic Commercial Alliance</h2></div>
        </header>
        <div className='text-justify lg:w-3/6 lg:text-left'>
          <p className='p-4'>
            From its beginnings, Korinver has been providing International Software Development Services. At the same time, Willdom has been developing a Latin American community of Senior Developers mainly oriented towards the American market.
          </p>
          <p className='p-4'>
            Since 2019, Korinver is working with Willdom to strengthen mutual capabilities. Through the integration of business and operational methods, and by using Willdom's network development system, we can offer additional technologies to a wider customer base.
          </p>
        </div>
        <div className='px-4 pt-4'>
          <div>
            <p>You can still take a look at our ongoing initiatives:</p>
          </div>
          <div className='int2 w-full my-5'>
            <a href="https://int2.korinver.com"><img src={int2} alt="Int Square" className='mx-auto my-5 lg:mx-0' /></a>
          </div>
          <div className='flex flex-col lg:flex-row lg:align-bottom justify-between md:pt-16 inset-x-0 bottom-0'>
            <div className='inline-block align-text-bottom'><p>Redirecting to Willdom in {seconds} seconds...</p></div>
            <div className='flex flex-col md:flex-row my-3 md:justify-around lg:justify-end'>
              <div className='items-center' ><img className='mx-auto my-9 lg:mx-0' src={korinver} alt="Korinver" /></div>
              <div className='items-center text-center' ><a href="https://willdom.com"><img className='mx-auto mb-5 mt-0' src={willdom} alt="Willdom" /></a></div>
            </div>
          </div>
        </div>
        {redirect}
      </div>
  )
}

export default Landing
