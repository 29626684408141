import { useEffect } from 'react';
import './App.css';
import Landing from './pages/Landing';

function App() {
  useEffect(() => {
    document.title = "Korinver";
  });

  return (
    <div className="App">
      <Landing />
    </div>
  );
}

export default App;
